<template>
<!-- 合作单位 -->
  <div class="about">
    <div class="nuox-about-header">
      <img src="http://39.104.95.82:8087/img/header/1.jpg" >
    </div>
    <div class="module-content">
      <div class="nuox-breadcrumb">
        <span class="nuox-breadcrumb-item"><router-link tag="span" :to="$t('lang.headerMenus[0].pageSrc')">{{ $t("lang.headerMenus[0].name") }}</router-link></span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item">{{ $t("lang.headerMenus[1].name") }}</span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item">{{ $t("lang.headerMenus[1].childrens[2].name") }}</span>
      </div>
      <div class="module-line"></div>
        <div :class="$i18n.locale=='en'?'module-nav-en':'module-nav'">
          <ul class="menu-vertical-nav">
            <li v-for="(item,index) in menus" :key="index" @click="checknav(item)">
              <div :class="'title '+[item.id==checkId?'open':'']" ><span>{{item.name}}</span><span v-if="item.childrens&&item.childrens.length>0" :class="[item.id==checkId?'el-icon-arrow-down':'el-icon-arrow-right']+ ' icon-down'"></span></div>
              <dl v-if="item.childrens && item.id==checkId && item.childrens.length>0">
                <dd :class="[item2.id==checkChildrenId?'in':'']" v-for="(item2,index2) in item.childrens" :key="index2" @click="checknavchildren(item,item2)">{{item2.name}}</dd>
              </dl>
            </li>
          </ul>
        </div>
        <div class="nuox-module">
          <div class="nuox-content-title">
            <div class="title-name">
              {{ $t("lang.headerMenus[1].childrens[2].name") }}
              <div class="xian"></div>
            </div>
          </div>
          <div class="nuox-module-cooperate-one">
            <img v-for="(item,index) in imgs" :key="index" :src="item.path" :class="item.className">
          </div>
        </div>
      <div class="module-line"></div>
    </div>
  </div>
</template>
<script>
export default {
  computed:{
    menus:function(){
      return this.$t('lang.headerMenus[1].childrens');
    }
  },
  data:function() {
    return {
        checkId:11,
        checkChildrenId:0,
        imgs:[]
    };
  },
  mounted(){
    this.init();
  },
  methods:{
   async init(){
      var locale =  this.$i18n.locale;
      var baseUrl = this.$baseURL;
      var that = this;
      const { data: res } = await this.$http.get(
        "webSite/about_cooperation_unit"
      );
       if(res.code==0){
         var data = res.data.data;
          var images =[];
          for(var key in data){
            var attList = data[key].attList;
            for(var index in attList){
              images.push({path:baseUrl+attList[index].attPath+attList[index].attRealName,className:'circular'});
            }
          }
          that.imgs = images;
       }
    },
    checknav(e){
      if(e.pageSrc){
          this.$router.push({ 
            path: e.pageSrc,
            query: {}
          })
      }
      this.checkId = e.id;
    },
    checknavchildren(item,item2){
      this.checkId=item.id;
      this.checkChildrenId=item2.id;
    }
  }
}
</script>
<style>
@import '../../assets/css/about.css';
.nuox-module-cooperate-one{
  text-align: center;
}
.nuox-module-cooperate-one img.circular{
  width: 140px;
  margin: 5px;
  border: 1px solid #f8f8f8;
}

</style>